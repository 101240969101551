import styles from './CommitToggle.module.css';
import { getClass } from 'utilities/classnames';

interface Props {
    on: boolean;
    onToggle: () => void;
}

export default function CommitToggle({ on, onToggle }: Props) {

    if (Boolean(process.env.REACT_APP_SHOW_COMMIT_TOGGLE)) {
        return (
            <div className={getClass([
                styles.commitToggle,
                on && styles.on,
                !on && styles.off
            ])} >
                <span>COMMIT TRANSACTION</span>
                <div onClick={onToggle}
                    className={getClass([
                        styles.toggle,
                        on && styles.on,
                        !on && styles.off
                    ])}>
                    <div className={styles.toggleIcon} />
                </div>
            </div>
        );
    } else {
        return null;
    }
}